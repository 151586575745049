import React, {Component} from 'react';
import './UserPersonaStyles.css'

export default class UserPersona extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
			<div className="user-story-card border user-persona-container mb-4">
				<div className="user-story-intro">
					<img src={this.props.data.image} />
					<div className="user-story-intro-details">
						<h4>{this.props.data.name}, {this.props.data.age}</h4>
						<h5>{this.props.data.title}</h5>
						<h6>
							<img src="/img/map-icon.svg" />
							<span>{this.props.data.location}</span>
						</h6>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="user-persona-section">
							<h5 className="user-persona-section-header">About</h5>
							<p className="user-persona-section-desc">
								{this.props.data.about}
							</p>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="user-persona-section mb-4">
							<h5 className="user-persona-section-header">Pain points</h5>
							<ul className="user-persona-section-list">
								{this.props.data.pain_points.map(function(item, index){
									return <li key={ index }>{item}</li>;
								})}
							</ul>
						</div>
						<div className="user-persona-section">
							<h5 className="user-persona-section-header">Goals</h5>
							<ul className="user-persona-section-list">
								{this.props.data.goals.map(function(item, index){
									return <li key={ index }>{item}</li>;
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}