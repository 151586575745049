import React from 'react';
import './WorkStyles.css';
import WorkCard from '../WorkCard/WorkCard';
import { work_items_featured } from '../../constants/work-items';
import { work_items } from '../../constants/work-items';

export const Work = () => (
<div>
	<h1 className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
		What I've been upto
	</h1>
	<div className="work-cards-container mt-4">
		{work_items_featured.map(item => (
			<WorkCard data={item} />
		))}
		{work_items.map(item => (
			<WorkCard data={item} />
		))}
	</div>
</div>
)