import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import ScrollIntoView from './components/ScrollIntoView/ScrollIntoView';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
		<ScrollIntoView>
		    <NavigationBar></NavigationBar>
	        <App />
	        <Footer/>
	    </ScrollIntoView>
    </BrowserRouter>,
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
