import React, { Component } from 'react';
import './HomeStyles.css';
import "../../css/animate.css";
import WorkCard from '../WorkCard/WorkCard';
import { work_items_featured } from '../../constants/work-items';
import Emoji from '../Emoji/Emoji';
const myTitles = ["a designer & maker", "a full stack designer", "a Malayalee", "a cyclist", "a minimalist", "a perfectionist", "a human being", "your friend", "a random guy", "a 90's kid", "a pixel pusher", "a coder", "an engineer", "a dreamer", "a problem solver", "a comic sans hater", "a traveller", "a work in progress", "Batman", "a user persona", "a design systems guy"];

export default class Home extends Component {
	
	constructor(){
		super();
		this.state = {
			currentTitle: myTitles[0],
		}
	    this.toggleTitle = this.toggleTitle.bind(this);
	}

	componentDidMount() {

    }

    componentWillUnmount() {

    }

	render() {
		return (
			<div>
				<div className="home-intro-container">
					<div className="dp-main-container animate__animated animate__fadeInUp animate__fast">
						<img src="img/dp-main.png" className="dp-main" />
						<img src="img/dp-mask.png" className="dp-mask" />
					</div>
					<div>
						<h1 className="main-header mb-0 mob-center d-xl-none animate__animated animate__fadeInUp animate__fast">
							Hey! I’m Anoop, a Full Stack Designer.
						</h1>
						<h1 className="main-header mb-0 d-none d-xl-block noselect animate__animated animate__fadeInUp animate__fast">
							Hey there! 
							<Emoji position="40" size="40" className="waving-hand ml-3" /><br/>
							I’m Anoop,
							<span className="d-none d-md-inline-block position-relative">
								<span onClick={this.toggleTitle} className="interactive-link">{this.state.currentTitle}</span>.
							</span>
						</h1>
						<p className="intro-text mt-3 mb-2 mob-center animate__animated animate__fadeInUp animate__fast">
							Currently crafting the future of banking solutions at Zeta. Previously at TapChief. Love cycling, reading and making stuff.
						</p>
					</div>
				</div>
				<div className="work-cards-container">
					{work_items_featured.map(item => (
						<WorkCard data={item} />
					))}
				</div>
			</div>
		);
	}

	toggleTitle(){
		let random = Math.floor(Math.random() * myTitles.length);
		this.setState({
			currentTitle: myTitles[random]
		})
	}

}
