import React, { Component }  from 'react';
import '../../css/article.css';

export default class ProjectDetails extends Component {

	constructor(){
		super();
		this.state = {
		}
	}

	render() {
		return (
			<div className="row project-details-container mt-5 mb-4">
				{this.props.timeline && (
					<div className="col-12 col-md-3">
						<h4 class="project-details-item-header mt-0 mb-2">Timeline</h4>
						<h6 class="project-details-item-desc mt-0">{this.props.timeline}</h6>
					</div>
				)}

				{this.props.platform && (
			    	<div className="col-12 col-md-4">
						<h4 class="project-details-item-header mt-0 mb-2">Platform</h4>
						<h6 class="project-details-item-desc mt-0">{this.props.platform}</h6>
					</div>
			    )}

				{this.props.role && (
					<div className="col-12 col-md-5">
						<h4 class="project-details-item-header mt-0 mb-2">Role</h4>
						<h6 class="project-details-item-desc mt-0">{this.props.role}</h6>
					</div>
				)}
			</div>
		);
	}
}

