import React, { Component }  from 'react';
import '../../css/article.css';
import SectionHeader from '../SectionHeader/SectionHeader';
import UserStory from '../UserStory/UserStory';
import CardOutline from '../CardOutline/CardOutline';
import ImageCaption from '../ImageCaption/ImageCaption';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import { work_items_featured } from '../../constants/work-items';
import MoreWork from '../MoreWork/MoreWork';
import UserPersona from '../UserPersona/UserPersona';
import {Helmet} from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const user_personas = [
	{
		name: 'Priyanka Gupta',
		age: '26',
		image: '/img/workspace/persona-2/dp.jpg',
		title: 'Freelance UI/UX Designer',
		location: 'MUMBAI, MH',
		about: 'Priyanka holds a Bachelors Degree in Interaction Design where she gained great knowledge about product designing and development. She worked as a Product Designer at a top service based MNC in India for 4 years and then took the plunge to move to freelancing. She mainly works with early stage startups, helping them craft their MVP and help them find the Product Market fit, working in close collaboration with the product team.',
		pain_points: [
			'Having to resort to a lot of tools to manage tasks, communications, invoices and payments is time consuming',
			'Unable to periodically track income and expenses systematically'
		],
		goals: [
			'Enhance daily productivity',
			'Keep clients updated of the daily progress involve them in the workflow'
		],
	},
	{
		name: 'Tony Thomas',
		age: '32',
		image: '/img/workspace/persona-1/dp.png',
		title: 'Runs a Freelance Content Studio',
		location: 'Bengaluru, KA',
		about: 'Tony runs a freelace content studio out of Bengaluru. He has 7+ years of experience strategising content for startups as well as enterprises. After a short stint of working as a full-time content strategist at a couple of organisations, he started his own content studio to help companies with their content needs. Tony and his team of five offers services in research, microsites, press releases, blog posts, UX copy, landing pages and more.',
		pain_points: [
			'With the team growing, it has become incredibly difficult to keep  structure for the tasks and track their progress',
			'Seamlessly sharing project progress and billing details with clients is a pain',
			'Duplicating the same proposal, invoices and tasks structure again and again is becoming cumbersome'
		],
		goals: [
			'Enhanced team productivity through better visibility of project progress and task statuses',
		],
	}
];

export default class Workspace extends Component {

	constructor(){
		super();
		this.state = {
		}
	}

	componentDidMount() {

    }

    componentWillUnmount() {

    }

	render() {
		return (
			<article className="mb-5">
				<Helmet>
					<title>Workspace by Anoop Sethumadhavan - Full Stack Designer</title>
					<meta name="description" content="All about how we designed an all in one toolkit for agencies, freelancers and small teams to manage everything from Proposals, Projects, Tasks and Invoices, for free."
				    />
				</Helmet>
				<h1 className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Workspace
				</h1>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Workspace by TapChief is an all-in-one toolkit for freelancers, agencies, small teams and studios to manage and grow their business. It is a full-stack product suite to manage everything from projects and tasks to invoices and proposals in one place, for free.
				</p>
				<ProjectDetails timeline="Nov - Jul 2020" platform="Desktop web" role="Research, IA, UI, UX, Visual Design" />
				<div className="bleeding-content mt-4 cover">
					<LazyLoadImage
 					effect='blur'
 					src='/img/workspace/cover.png' wrapperClassName="cover-bg" />
				</div>
				<SectionHeader number="01" title="The Beginning"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Managing speadsheets, handling deadlines, making client payments, doing back & forth calls - these are all day-today problems faced by freelancers, agencies, studios and small teams. Watching our customers using one tool to create proposals, another for drafting contracts, yet another one for managing tasks just sounded overwhelming to us.</p>
				<img src='/img/workspace/image-1.png' className="normal-image" />
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					We realised it doesn’t have to be this way; and decided to build TapChief Workspace so that people can spend more time doing what they love, than managing it.
				</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					At its core, Workspace was imagined to be designed as a virtual desk based architecture, centered around the pillars of productivity, collaboration, reporting and communication, while ensuring compatibility with existing platforms and system support with the help of integrations. 
				</p>
				<img src='/img/workspace/mindmap.png' className="normal-image mb-5" />


				<SectionHeader number="02" title="User Personas"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					We started by creating personas and fictional representations of our target users. This, along with qualitative preliminary research helped us build empathy with the audience, identify exactly what they need from Workspace and guide our design decisions from start to end. We tried to incorporate multiple attributes like the needs, goals, behavioural patterns of our target audience in the user persona. 
				</p>

				<UserPersona data={user_personas[0]} />
				<UserPersona data={user_personas[1]} />

				<SectionHeader number="02" title="Research & User Stories"></SectionHeader>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					We considered user stories to be a way of expressing software features as a solution to user needs rather than a requirement document. After our preliminary user research (qualitative - online surveys, telephonic and in-person conversations) we came up with user stories for every single pain point faced by our target audience (service providers or freelancing professionals) and mapped them to potential solutions we could come up with.
				</p>

				<div className="mt-4 mb-4">
					<div className="row">
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/female-technologist_1f469-200d-1f4bb.png" background_color="#FFF1E4" description="As a Service Provider, I want to see the new  projects leads I generate in one place so that I don't lose track of them."></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/person-in-lotus-position_1f9d8.png" background_color="#E4FAFF" description="As a Service Provider, I want to be able to create tasks for my team so that we can structure our work and progress."></UserStory>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/speech-balloon_1f4ac.png" background_color="#EAE4FF" description="As a Service Provider, I want to send a message to my contacts to start chatting with them."></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/money-bag_1f4b0.png" background_color="#FFE4E4" description="As a Service Provider, I want to track my income and expenses so that I can maintain books for my business."></UserStory>
						</div>
					</div>
				</div>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					This helped us narrow down on the feature set (listed below) and build exactly what our target audience needed. We also drafted a few core principles and planned to adhere to it so that we set product vision and mission right.
				</p>
				<div className="animate__animated animate__fadeInUp animate__fast">
					<p className="intro-text mt-0 mb-2 d-flex align-items-start">
						<img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/flexed-biceps_1f4aa.png" />
						<span><b>Full-Stack</b>: To ensure the user had access to every tool he/she needs in a freelancing lifestyle</span>
					</p>
					<p className="intro-text mt-0 mb-2 d-flex align-items-start">
						<img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/memo_1f4dd.png" />
						<span><b>Templates</b>: A customisable blueprint structure to assist users setting up projects, tasks, invoices and proposals</span>
					</p>
					<p className="intro-text mt-0 mb-2 d-flex align-items-start">
						<img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/open-lock_1f513.png" />
						<span><b>Customisability</b>: To ensure Workspace always acts as a tool that adapts itself to the professional's work, not the other way around</span>
					</p>
				</div>
				<h3 className="mt-5 mb-4">Set of intended features</h3>
				<div className="row">
					<div className="col-12 col-lg-6">
						<UserStory image="/img/workspace/projects-icon.png" background_color="#FFFFFF" className="border" title="Projects" description="To track progress of multiple collaborations, customise their lifecycle and work with clients & team members"></UserStory>
					</div>
					<div className="col-12 col-lg-6">
						<UserStory image="/img/workspace/finances-icon.png" background_color="#FFFFFF" className="border" title="Finances" description="To auto-create invoices from tasks, receive payments securely and create transaction statements for easy tax filing"></UserStory>
					</div>
				</div>
				<div className="row mb-5 pb-1">
					<div className="col-12 col-lg-6">
						<UserStory image="/img/workspace/todo-icon.png" background_color="#FFFFFF" className="border" title="To-dos" description="To plan a day better with events, tasks, milestones, assign to-dos and receive alerts on due dates"></UserStory>

					</div>
					<div className="col-12 col-lg-6">
						<UserStory image="/img/workspace/contacts-icon.png" background_color="#FFFFFF" className="border" title="Contacts" description="To help users collaborate with their clients and teams in text-based chat rooms and use video calls for screen sharing"></UserStory>

					</div>
				</div>

				<SectionHeader className="mt-5" number="03" title="Projects Module"></SectionHeader>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					The module was designed in such a way that it offers a birds-eye view of all projects of a user across various stages, clients, budgets and types. The Project Home, is structured like a visual database. Each Project is an entity (row) with properties (columns) associated with them. Apart from the table view of all projects, the home IA accommodates key actions for addition, sort and filters. Based on our internal usability tests, we introduced the concept of boards to help users classify projects based on their stage, type and client.
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/workspace/screen-1.png" caption="The project boards view. Boards can have projects classified based on their stage, type and client." />
					<ImageCaption fullWidth="true" image="/img/workspace/screen-2.png" caption="The project list view, structured like a visual database" />
				</div>

				<SectionHeader className="mt-5" number="04" title="To-dos Module"></SectionHeader>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					To-dos help our users define and track the core output of the job done for a project. We designed this feature set to help users set tasks (& sub tasks), assign due dates, payments, milestones and more. We imagined it as a larger umbrella which covers anything our users have to-do in a day, for a project or for their own productivity - which could be meetings, calls, project tasks, hitting a milestone and more. To facilitate this, we designed Calendar and Kanban type views in addition to the default listing view of to-dos.
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/workspace/screen-3.png" caption="We implemented the To-do calendar-view to help users view to-dos they need to complete by day" />
				</div>


				<SectionHeader className="mt-5" number="05" title="Finances Module"></SectionHeader>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					The module covers all aspects of book-keeping, payments, projections and invoices. The IA of the Finances home was carefully crafted in such a way that it gives an overview of a user's P&L in a given month and projected earnings next month to monitor their financial health. In addition to that, we also added support for manual entries of incomes and expenses, projections and payment links.
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/workspace/screen-4.png" caption="The Finances Home: A birds eye view of all expenses and financial summary" />
				</div>


				<SectionHeader className="mt-5" number="06" title="Chat & Contacts Module"></SectionHeader>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					The Contacts module equip our users with messaging, payment updates, file uploads, audio & video calls and more in an environment similar to Slack, but on steroids. Chat was designed as a fundamental layer of the Workspace that acts as an underlying communication feature for projects, facilitating easy-contact with clients and team members.
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/workspace/screen-5.png" caption="Chat Module: An easy way for users to organize chats and contacts so that they never miss a conversation" />
				</div>

				<SectionHeader className="mt-5" number="07" title="Identity and Branding"></SectionHeader>
				<p className="mt-0 mb-0 animate__animated animate__fadeInUp animate__fast">
					We wanted Workspace to have a futuristic identity so that it falls well within our vision of building tools for the Future of Work. After a few brainstorming sessions, we came up with a logomark which had a unique identity of it’s own while blending well with the existing TapChief brand guidelines.
				</p>

				<img src='/img/workspace/image-2.png' className="normal-image" />

				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					The idea was to brand workspace as the only suite of project management-collaboration tools a freelancer will ever need. Definitive, period.
				</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					The dot, along with the all-low-case logotype symbolifies the approachability of the brand while marking the completeness and simplicity of the product. To sum it up, it represents the spark of an idea, the business, the client or the freelancer -  every stakeholder associated with a project.
				</p>
				<ImageCaption fullWidth="false" image="/img/workspace/image-3.png" caption="Individual identities designed for each core module of Workspace" /> 
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					We also came up with a cohesive branding scheme for all core modules of Workspace so that the whole visual experience is consistent off the product as well.
				</p>

				<SectionHeader className="mt-5" number="08" title="Early access campaign"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					After 3 months of furious building under the COVID-19 lockdown, we opened up early access to Workspace. We were genuinely surprised by the overwhelming response from the community - we hit 2000 subscribers within the first four days with a conversion rate of 33.8%. According to HubSpot, "On average, landing pages generate a 5–15% conversion rate".
				</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp  animate__fast">
					We also received some amazing feedback on the feature previews and snapshots we pushed on our social media channels. Our whole team is totally pumped with the crazy response we received and are now working on polishing the experience and fixing bugs before we ship.
				</p>
				<div className="row">
					<div className="col-12 col-lg-4">
						<img src="/img/workspace/IG_Story1.png" className="normal-image border mb-4" />
					</div>
					<div className="col-12 col-lg-4">
						<img src="/img/workspace/IG_Story3.png" className="normal-image border mb-4" />
					</div>
					<div className="col-12 col-lg-4">
						<img src="/img/workspace/IG_Story4.png" className="normal-image border mb-4" />
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-lg-4">
						<img src="/img/workspace/IG_Story5.png" className="normal-image border mb-4" />
					</div>
					<div className="col-12 col-lg-4">
						<img src="/img/workspace/IG_Story7.png" className="normal-image border mb-4" />
					</div>
					<div className="col-12 col-lg-4">
						<img src="/img/workspace/IG_Story9.png" className="normal-image border mb-4" />
					</div>
				</div>
				<div>
					<p className="mt-0 mb-5 image-caption">A few promotional materials designed for the early access campaign</p>
				</div>


				<p className="mt-0 mb-4 animate__animated animate__fadeInUp d-none  animate__fast">
					We received some amazing feedback on the feature previews and snapshots we pushed on our social media channels. Our whole team is totally pumped with the crazy response we got and are now working on polishing the experience and fixing bugs before we ship.
				</p>

				<hr/>
				<MoreWork item1={work_items_featured[1]} item2={work_items_featured[2]} />

			</article>
		);
	}
}

