import React, { Component }  from 'react';
import WorkCard from '../WorkCard/WorkCard';

export default class MoreWork extends Component {

	constructor(){
		super();
		this.state = {
		}
	}

	componentDidMount() {

    }

    componentWillUnmount() {

    }

	render() {
		return (
			<div>
				<h2 className="mt-5 pt-4 mb-4 align-center">More stories to explore</h2>
				<div className="bleeding-content mt-4 pt-3">
					<div className="container-bleeding">
						<div className="row">
							<div className="col-12 col-lg-6">
								<WorkCard type="small" class="work-card-container-sm" data={this.props.item1} />
							</div>
							<div className="col-12 col-lg-6">
								<WorkCard type="small" class="work-card-container-sm" data={this.props.item2} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

