import React from 'react';
import 'normalize.css'; // Note this
import './css/bootstrap-grid.min.css';
import './css/styles.css';
import { Route, Switch } from 'react-router-dom';
import Home from './components/Home/Home';
import { Work } from './components/Work/Work';
import { Words } from './components/Words/Words';
import { About } from './components/About/About';
import Contact from './components/Contact/Contact';
import Workspace from './components/Workspace/Workspace';
import Webinars from './components/Webinars/Webinars';
import Crew from './components/Crew/Crew';
import ThirtyDays from './components/30doml/30doml';
import Washiewash from './components/Washiewash/Washiewash';

function App() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-12">
          <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/about" component={About} />
              <Route exact path="/work" component={Work} />
              <Route exact path="/words" component={Words} />
              <Route path="/contact" component={Contact} />
              <Route path="/work/workspace" component={Workspace} />
              <Route path="/work/webinars" component={Webinars} />
              <Route path="/work/crew" component={Crew} />
              <Route path="/work/30-days-of-malayalam-letters" component={ThirtyDays} />
              <Route path="/work/washiewash" component={Washiewash} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
