import React, {Component} from 'react';
import './CardOutlineStyles.css';

export default class CardOutline extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
		<div className="card-outline p-3">
			<p className="intro-text m-0 d-flex align-items-start">
				<img src={this.props.image} />
				{this.props.title}
			</p>
		</div>
		)
	}
}