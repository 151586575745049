import React, {Component} from 'react';
import  './BlogCardStyles.css';

export default class BlogCard extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
			<a className="blog-card animate__animated animate__fadeInUp animate__fast" href={this.props.url} target="_blank">
				<img className="blog-card-thumb" src={this.props.thumb} />
				<div className="blog-card-content">
					<div>
						<h2 className="header">
							{this.props.title}
						</h2>
						<p className="description">
							{this.props.description}
						</p>
					</div>
					<img src={this.props.publication_logo} className="publication-logo" />
				</div>
			</a>
		)
	}
}