import React from 'react';
import BlogCard from '../BlogCard/BlogCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Words = () => (
<div className="">
	<h1 className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
		Thoughts on design, products & more
	</h1>
	<div className="mt-3">
		<BlogCard title="Figma Community: the Github for designers" description="An appreciation piece on how well-modelled the Figma Community feature is and how it can possibly change the way the world designs digitally" thumb="/img/blog/figma.png" publication_logo="/img/blog/uxcollective.png" url="https://uxdesign.cc/figma-community-the-github-for-designers-b2261d4d21be" />
		<BlogCard title="How we built a webinar listing platform in just 3 days, remotely" description="The TapChief experience of designing, building and launching a side-project, a Webinars Listing Platform, all from scratch, in a timeline of 3 days" thumb="/img/blog/webinar.png" publication_logo="/img/blog/uxcollective.png" url="https://uxdesign.cc/how-we-built-a-webinar-listing-platform-in-just-3-days-remotely-32af4ce2c388" />
		<BlogCard title="A full-stack designer’s take on a daily lettering challenge" description="All about how I got fascinated by the world of Type and participated in 30 Days of Malayalam Letters, crafting my thoughts in pure HTML/CSS" thumb="/img/blog/30doml.png" publication_logo="/img/blog/uxcollective.png" url="https://uxdesign.cc/a-full-stack-designers-take-on-a-daily-lettering-challenge-27385310b40a" />
	</div>
</div>
)