import React, {Component} from 'react';

export default class Quote extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
			<div className="mt-5 mb-5 quote">
				<img src="/img/quote.png" className="mb-3" />
				<p className="m-0">{this.props.text}</p>
			</div>
		)
	}
}