import React, {Component} from 'react';
import './SectionHeaderStyles.css';

export default class SectionHeader extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
		<div className={`section-header ${this.props.className} `}>
			<h6 className="number">{this.props.number}</h6>
			<h2 className="title">{this.props.title}</h2>
		</div>
		)
	}
}