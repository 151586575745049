import React from 'react';
import './AboutStyles.css';
import Emoji from '../Emoji/Emoji';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const About = () => (
<div className="">
	<h1 className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
		Dreamer, Designer, Maker.
	</h1>
	<div className="mt-3">
		<div className="row">
			<div className="col-md-3">
				<div className="about-image-container">
					<LazyLoadImage visibleByDefault='true'
 					effect='blur'
					src='img/about-full-image.png' 
					wrapperClassName="about-full-image animate__animated animate__fadeInUp animate__fast" />
					<img src="img/egg.png" className="about-easter-egg" />
					<p className="easter-egg-text">OK, here's an easter egg</p>
				</div>
			</div>
			<div className="col-md-9">
				<p className="intro-text intro-text-2 mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					I'm Anoop, a passionate product designer, front-end developer and maker with 4+ years of experience crafting web and mobile experiences.
				</p>
				<p className="intro-text intro-text-2 mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					I consider design as a tool to build functional and beautiful experiences solving real-world problems by bringing user and business goals together.
				</p>
				<p className="intro-text intro-text-2 mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					When not pushing pixels, I enjoy cycling, reading and making stuff.
				</p>
			</div>
		</div>
	</div>

	<h2 className="mt-5 mb-4 animate__animated animate__fadeInUp animate__fast">
		The Road Thus Far
	</h2>
	<div className="mt-4 timeline-container">
		<div className="timeline-item animate__animated animate__fadeInUp animate__fast">
			<div className="timeline-year d-flex align-items-center">2013</div>
			<div className="timeline-icon timeline-icon-1">
				<Emoji size="32" position="-96" className="m-0" />
			</div>
			<div className="timeline-content d-flex align-items-center">
				<div>
					<h5>UI/UX Design Intern</h5>
					<p>EduLyft <span className="d-none d-md-inline-block">(Rebranded to Edvice)</span></p>
				</div>
			</div>
		</div>

		<div className="timeline-item animate__animated animate__fadeInUp animate__fast">
			<div className="timeline-year d-flex align-items-center">2015</div>
			<div className="timeline-icon timeline-icon-2">
				<Emoji size="32" position="-128" className="m-0" />
			</div>
			<div className="timeline-content d-flex align-items-center">
				<div>
					<h5>UI Design Intern</h5>
					<p>Hashlearn <span className="d-none d-md-inline-block">(Acquired Edvice)</span></p>
				</div>
			</div>
		</div>
		<div className="timeline-item animate__animated animate__fadeInUp animate__fast">
			<div className="timeline-year d-flex align-items-center">2016</div>
			<div className="timeline-icon timeline-icon-3">
				<Emoji size="32" position="-160" className="m-0" />	
			</div>
			<div className="timeline-content d-flex align-items-center">
				<div>
					<h5>Product Designer<span className="d-none d-md-inline-block">, Founding Team</span></h5>
					<p>TapChief</p>
				</div>
			</div>
		</div>
		<div className="timeline-item animate__animated animate__fadeInUp animate__fast">
			<div className="timeline-year d-flex align-items-center">2018</div>
			<div className="timeline-icon timeline-icon-4">
				<Emoji size="32" position="-192" className="m-0" />
			</div>
			<div className="timeline-content d-flex align-items-center">
				<div>
					<h5>Design Lead</h5>
					<p>TapChief</p>
				</div>
			</div>
		</div>
		<div className="timeline-item animate__animated animate__fadeInUp animate__fast">
			<div className="timeline-year d-flex align-items-center">2020</div>
			<div className="timeline-icon timeline-icon-5 timeline-icon-current">
				<Emoji size="32" position="-224" className="m-0" />
			</div>
			<div className="timeline-content d-flex align-items-center">
				<div>
					<h5>Senior UX Designer</h5>
					<p>Zeta</p>
				</div>
			</div>
		</div>
	</div>
	<div className="mt-3">
		<p className="intro-text intro-text-2 mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
			My design journey began at BITS Pilani in 2014 when I joined as an intern at Edvice, a platform connecting students preparing for university entrance examinations with tutors, on-demand. We later got acquired by Hashlearn, making us one of the very few in India to accomplish a successful startup exit while at college. 
		</p>
		<p className="intro-text intro-text-2 mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
			I then joined TapChief as a Product Designer and one of the founding team members. Since the last 4 years, I've been building the design function at TapChief, crafting the Future of Work. We're designing experiences helping professionals build their network, connect to new opportunities and bag high-value projects by monetizing their time.
		</p>
	</div>
</div>
)
