import React, {Component} from 'react';
import './FooterStyles.css';

export default class WorkCard extends Component {
	constructor(){
		super();
	}
	render() {
	return (
		<nav className="footer">
			<div className="container">
				<div className="row justify-content-center">
					<div class="col-md-12 text-center">
						<div className="footer-seal-container">
							<img className="footer-seal" src="/img/footer-seal.png" />
							<img className="footer-seal-heart" src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/whatsapp/238/heavy-black-heart_2764.png" />
						</div>
					</div>
					<div className="col-lg-8 col-md-12">
						<div className="row">
							<div className="col-12 center">
								<p className="footer-title d-block mt-1">Handcrafted with Figma, React & <a href="https://www.thebetterindia.com/135601/sulaimani-tea-history-kerala/" className="normal-link" target="_blank">Sulaimani</a></p>	
							</div>
							<div className="col-12">
								<div className="navbar-link-container">
									<a className="navbar-link" target="_blank" href="https://www.linkedin.com/in/anoopsethumadhavan/">
										<img src="/img/linkedin.svg" />
									</a>
									<a className="navbar-link dribbble-link" target="_blank" href="https://dribbble.com/anoopsethu">
										<img src="/img/dribbble.svg" />
									</a>
									<a className="navbar-link behance-link" target="_blank" href="https://www.behance.net/anoopsethu">
										<img src="/img/behance.svg" />
									</a>
									<a className="navbar-link github-link" target="_blank" href="https://github.com/anoopsethu">
										<img src="/img/github.svg" />
									</a>
									<a className="navbar-link" target="_blank" href="https://twitter.com/anoopsethu">
										<img src="/img/twitter.svg" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
	}
}