export const work_items_featured = [
	{
		title: 'TapChief Workspace',
		description: 'Designed an all in one toolkit for agencies, freelancers and small teams to manage everything from Proposals, Projects, Tasks and Invoices.',
		role: 'Research, IA, UI, UX ',
		image: '/img/workspace-card-preview.png',
		background_color: '#FFF1E4',
		internal_link: '/work/workspace',
		id: '001',
	},
	{
		title: '30 Days of Malayalam Letters',
		description: 'All about how I got fascinated by the world of Type and participated in a lettering challenge, crafting my thoughts in HTML/CSS.',
		role: 'lettering, daily challenge ',
		image: '/img/30doml-1-card-preview.png',
		background_color: '#EFEBFF',
		internal_link: '/work/30-days-of-malayalam-letters',
		id: '004',
	},
	{
		title: 'TapChief Webinars',
		description: 'Designed & built a platform to host, organise and promote free online webinars from around the world.',
		role: 'ideation, research, ux/ui',
		image: '/img/webinars-card-preview.png',
		background_color: '#E4FAFF',
		internal_link: '/work/webinars',
		id: '002',
	},
	{
		title: 'Washiewash',
		description: 'Built a Google Chrome extension that sends configurable timed notifications, reminding people to wash hands frequently.',
		role: 'ideation, UI, UX, code',
		image: '/img/washiewash-card-preview.png',
		background_color: '#FFF1E4',
		internal_link: '/work/washiewash',
		id: '005',
	},
	{
		title: 'TapChief Crew',
		description: 'Designed a recruiting software that enables businesses to seamlessly manage applicants and other professionals in their hiring funnel.',
		role: 'research, ia, UI, UX ',
		image: '/img/crew-card-preview.png',
		background_color: '#FFE4E4',
		internal_link: '/work/crew',
		id: '003',
	},
];

export const work_items = [
	{
		title: 'Skill For Kerala',
		description: 'Designed an initiative where artists and designers could donate their skill towards the rebuilding of Kerala hit by the floods of 2018.',
		role: 'visual design, code',
		image: '/img/sfk-card-preview.png',
		background_color: '#FFF1E4',
		external_link: 'https://kdco.info/skillforkerala/',
		id: '006',
	},
	{
		title: 'Charapara',
		description: 'Designed the first dummy text generator for Malayalam language with crowdsourced words from the local designer community.',
		role: 'ideation, ux, ui, code',
		image: '/img/charapara-card-preview.png',
		background_color: '#FFE4E4',
		external_link: 'https://charapara.in/',
		id: '007',
	},
	{
		title: 'Hashtag Movies App',
		description: 'Designed a platform to explore and share upcoming local entertainments (movies, plays and events), surveys and interesting trivia.',
		role: 'visual design, consultation',
		image: '/img/hashtag-card-preview.png',
		background_color: '#FFF1E4',
		external_link: 'https://www.instagram.com/hashtagmovies_app/',
		id: '008',
	},
	{
		title: 'Hashlearn Now Infographic',
		description: 'Designed and built an interactive story describing what most students go through to crack the competetive entrance exams.',
		role: 'ideation, visual design, code',
		image: '/img/infographic-card-preview.png',
		background_color: '#EFEBFF',
		external_link: 'https://now.hashlearn.com/infographic/',
		id: '009',
	},
	{
		title: 'Brightnews',
		description: 'Built a platform to host a curated list of good things happening around the world. Also, your daily dose of morning goodness.',
		role: 'ideation, UI, UX, code',
		image: '/img/brightnews-card-preview.png',
		background_color: '#FFF1E4',
		external_link: 'https://brightnews.co/',
		id: '010',
	},
	{
		title: 'Dextra',
		description: 'Worked on setting up general design principles and branding guidelines for an online collaboration platform targetting the creative community.',
		role: 'Branding, UI/UX Consultation',
		image: '/img/dextra-card-preview.png',
		background_color: '#E4FAFF',
		external_link: 'https://www.dextra.com/',
		id: '010',
	},
];