import React, { Component }  from 'react';
import '../../css/article.css';
import SectionHeader from '../SectionHeader/SectionHeader';
import UserStory from '../UserStory/UserStory';
import CardOutline from '../CardOutline/CardOutline';
import ImageCaption from '../ImageCaption/ImageCaption';
import Quote from '../Quote/Quote';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import { work_items_featured } from '../../constants/work-items';
import MoreWork from '../MoreWork/MoreWork';
import {Helmet} from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class Crew extends Component {

	constructor(){
		super();
		this.state = {
		}
	}

	componentDidMount() {

    }

    componentWillUnmount() {

    }

	render() {
		return (
			<article className="mb-5">
				<Helmet>
					<title>TapChief Crew by Anoop Sethumadhavan - Full Stack Designer</title>
					<meta name="description" content="All about how we designed a ecruiting software to hire & onboard freelancers & experts. From job posts to contracts, it has the tools to hire the best remote talent, fast."
				    />
				</Helmet>
				<h1 className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					TapChief Crew
				</h1>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					TapChief Crew is an ATS (Applicant Tracking System) that enables businesses to seamlessly manage applicants and other professionals in their hiring funnel. 
				</p>
				<ProjectDetails timeline="Mar - Aug 2019" platform="Desktop web" role="Research, IA, UI, UX, Visual Design" />
				<div className="bleeding-content mt-4">
					<LazyLoadImage wrapperClassName="cover-bg"
 					effect='blur'
					src='/img/crew/cover.png' />
				</div>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Crew aims to enable businesses - small and big, manage their engagement requests on TapChief to the point that they finalise the right set of professional(s) they’d like to work with for a particular request.
				</p>


				<SectionHeader number="01" title="The problem"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					TapChief launched it’s Marketplace offering in Feb 2019, enabling Discovery & Transactions amongst multiple buyers & Sellers in the domain of people commerce. Since then, we’ve been doing frequent usability surveys with our users collecting feedback and getting insights on how to improve the product experience.
				</p>
				<Quote text="While users were quite happy with the whole marketplace lifecycle, we realised that they wanted the post-purchase lifecycle experience also to be productised."></Quote>
				<p className="mt-0 mb-4">
					Till then we had this done off the platform with our in-house operations and customer success team handling it end-to-end. So we set out to build TapChief Crew, a tool that can help clients manage, shortlist and finalise applicants so that they can work with them.
				</p>


				<SectionHeader number="02" title="User Research"></SectionHeader>
				<p className="mt-0 mb-5">
					The problem statement was pretty much clear, but we had to drill-down a bit on the exact set of user intents. Hence we scheduled a series of user research calls with our Marketplace users to understand their pain points and product expectations. The insights were really interesting.
				</p>
				<div className="mt-4 mb-4">
					<div className="row">
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/memo_1f4dd.png" background_color="#FFF1E4" description="I want to manage my applicants, share assignments, shortlist and finalise them."></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/speech-balloon_1f4ac.png" background_color="#E4FAFF" description="I should be able to schedule interviews and have asynchronous conversations with the applicants."></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/chart-with-upwards-trend_1f4c8.png" background_color="#EAE4FF" description="I should be able to assess the skills and experience of the applicants on a standardised scale."></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/briefcase_1f4bc.png" background_color="#FFE4E4" description="I want to share contract documents and map out scope & timeline of the project."></UserStory>
						</div>
					</div>
				</div>
				<p className="mt-0 mb-5">
					After a series of feedback sessions on the user stories and expectations, we narrowed down on a set of features we thought would solve the user pain points.
				</p>
				<div className="row">
					<div className="col-12 col-lg-6">
						<UserStory image="/img/crew/dashboard-icon.png" background_color="#FFFFFF" className="border" title="Business dashboard" description="A single place to view all engagements across states, wallet balance, invoices, co-workers on the team, notifications and more."></UserStory>
					</div>
					<div className="col-12 col-lg-6">
						<UserStory image="/img/crew/hiring-icon.png" background_color="#FFFFFF" className="border" title="Hiring Funnel" description="A live, dynamic funnel to view and manage applicants across multiple stages of the hiring process from applied to shortlisted right uptil hired."></UserStory>
					</div>
					<div className="col-12 col-lg-6">
						<UserStory image="/img/crew/chat-icon.png" background_color="#FFFFFF" className="border" title="Universal Chat" description="A powerful, all encompassing communications (and logs) layer for users to chat, call, exchange files, negotiate and collaborate on proposals."></UserStory>
					</div>
					<div className="col-12 col-lg-6">
						<UserStory image="/img/crew/video-icon.png" background_color="#FFFFFF" className="border" title="Audio/Video Calls" description="On-product functionality to schedule and directly make a phone call (Cloud telephony) and start a video call to communicate with applicants seamlessly"></UserStory>
					</div>
					<div className="col-12 col-lg-6">
						<UserStory image="/img/crew/profile-icon.png" background_color="#FFFFFF" className="border" title="Showcase" description="A super charged profile with the applicant's work experience, portfolio, rating, education, testimonials and more to help users make a buying decision."></UserStory>
					</div>
					<div className="col-12 col-lg-6">
						<UserStory image="/img/crew/contract-icon.png" background_color="#FFFFFF" className="border" title="Contracts" description="A tool to outline the scope, timeline and pricing of the engagement and to enable seamless back and forth on the same to power negotiations on the platform."></UserStory>
					</div>
				</div>

				<SectionHeader className="mt-5" number="03" title="Business Dashboard"></SectionHeader>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					Inspired by a kanban board, the business dashboard was designed to offer an overview of all projects and the states they are in to the user. The Information Architecture follows a flow of urgency from most important (actively running engagements a user has paid for) to ones that require no immediate attention (completed projects with an attached invoice and feedback).
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/crew/screen-1.png" caption="The Crew Business Dashboard for the client side, listing projects on kanban boards" />
				</div>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					The dashboard also give users access to auxiliary and passive actions like posting a project, inviting collaborators, rehiring a freelancer and universal chat. 
				</p>


				<SectionHeader className="mt-5" number="04" title="Universal Chat"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					The goal of the universal chat is three fold; Easy Communications for 1-on-1 and group interactions, Building an environment with core actions accessible from chat, a universal log of events, interactions and negotiations for our users. 
				</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					The chat IA was defined in such way that it's universally present throughout out the Crew flow. We made sure the visual design was familiar and simple enough so that the learning curve is shallow.
				</p>
				<div className="mt-3 mb-4">
					<div className="row">
						<div className="col-12 col-lg-6">
							<UserStory className="height-auto" image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/paperclip_1f4ce.png" background_color="#FFE4E4" description="Share and receive documents and files of different type"></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory className="height-auto" image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/memo_1f4dd.png" background_color="#E4FAFF" description="Request, draft, send and receive project proposals"></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory className="height-auto" image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/black-telephone_260e.png" background_color="#FFF1E4" description="Schedule and initiate phone and video Calls"></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory className="height-auto" image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/money-bag_1f4b0.png" background_color="#EAE4FF" description="Track all reminders, negotiations and payments"></UserStory>
						</div>
					</div>
				</div>
				<h3 class="mt-5 mb-4">Critical features of the Chat Module</h3>
				<div className="bleeding-content">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-md-4 mb-4">
								<img src='/img/crew/screen-2.png' />
								<p className="image-caption"><b>P2P Chat</b>: 1-on-1 chat with an option to share files, start a video/phone call</p>
							</div>
							<div className="col-md-4 mb-4">
								<img src='/img/crew/screen-3.png' />
								<p className="image-caption"><b>Integrations</b>: In-chat functionality to schedule interviews, send & request proposals</p>

							</div>
							<div className="col-md-4">
								<img src='/img/crew/screen-4.png' />
								<p className="image-caption text-center"><b>Activity log</b>: A central log of all events, reminders & negotiations for unified tracking</p>

							</div>
						</div>
					</div>
				</div>

				<SectionHeader className="mt-5" number="05" title="Hiring Funnel"></SectionHeader>
				<p className="mt-0 mb-5 animate__animated animate__fadeInUp animate__fast">
					Hiring funnel is a feature for our users to manage applicants to their engagements across various stages seamlessly. The hiring funnel is built using loose ordering to account for the many different ways a user may move their applicants through the funnel to arrive at a common end-point, hiring them. 
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/crew/screen-5.png" caption="The hiring funnel with applicant listing cards" />
				</div>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					The hiring funnel is a 3-step funnel with different stages classifying the candidates into the following: 
				</p>
				<div className="mt-4 mb-4">
					<div className="row">
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/female-technologist_1f469-200d-1f4bb.png" background_color="#FFF1E4" title="Applicants" description="All Applicants pending any sort of action (Shortlist, Archive, Chat, Scheduled Interview)"></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/black-telephone_260e.png" background_color="#E4FAFF" title="Contacted" description="Applicants who’ve been contacted or have initiated contact with the client lie in this state."></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/handshake_1f91d.png" title="Hired" background_color="#EAE4FF" description="These are freelancers who’ve accepted a contract and the contract has been paid for by the user."></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/disappointed-face_1f61e.png" title="Archived" background_color="#FFE4E4" description="Applicants that the user finds unsuitable for their engagement are marked as archived"></UserStory>
						</div>
					</div>
				</div>


				<SectionHeader className="mt-5" number="06" title="Audio/Video Calls"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Audio and Video calls allow our users to have highly contextual 1-on-1 conversation with freelancers. The feature is built on top of familiar usage users expect (screen sharing, in-call chat etc) out of such communication tools and to mimic how such communication takes place in the status quo; with a scheduled event on a calendar and a proactive call initiated at the time of the event with a link to join the call-room.
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/crew/screen-6.png" caption="The already familiar UX patterns on the call screen helped us cut down the onboarding efforts by a huge margin" />
				</div>


				<SectionHeader className="mt-5" number="07" title="Showcase"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					The TapChief Expert Profile (Showcase, as we call it - because it is essentially the place where a professional showcases himself/herself listing down his/her expertise, portfolio, skills and experience) is an independent product of its own and within the context of Crew, it acts like a filtered view of the master profile with configurable fields. The profile help our users make a buying decision on the right freelancer to hire for their engagement. 
				</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/crew/screen-7.png" caption="The customised showcase module with a feedback widget added" />
				</div>


				<SectionHeader className="mt-5" number="08" title="Contracts"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Contracts allow users to define the final scope of the project and send it across to a freelancer they’ve shortlisted. Users can attach text, files, billing cycles, deposit an amount and set project milestones & timelines on a TapChief Contract. The aim of Contracts on TapChief is to empower the users and reduce Turn Around Time, operation overheard and loss of communication in a three party marketplace. 
				</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Contracts can be accessed from a freelancer card, in-chat or from people search on Crew. Access points to contracts have been contextually chosen based on the places where buying intent is most likely to be expressed. 
				</p>
				<ImageCaption fullWidth="false" image="/img/crew/screen-8.png" caption="Guided UI structure of the Contracts module" />

				<hr/>
				<MoreWork item1={work_items_featured[3]} item2={work_items_featured[0]} />

			</article>
		);
	}
}

