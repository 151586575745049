import React, {Component} from 'react';
import './ImageCaptionStyles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class ImageCaption extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
		this.props.fullWidth=='true'?
			<div className="image-container">
				<img src={this.props.image} />
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12 col-lg-8">
							<p className="m-0 image-caption">{this.props.caption}</p>
						</div>
					</div>
				</div>
			</div>
			:
			<div className="mb-5">
				<img className="normal-image mb-3"
					src={this.props.image} />
				<p className="m-0 image-caption">{this.props.caption}</p>
			</div>
		)
	}
}