import React, {Component} from 'react';
import './UserStoryStyles.css';

export default class UserStory extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
		<div className={`user-story-card ${this.props.className}`} style={{backgroundColor: this.props.background_color}}>
			<img src={this.props.image} />
			{this.props.title?
			<h3 className="mb-3 mt-2">{this.props.title}</h3>
			:<span></span>
			}
			<p>{this.props.description}</p>
		</div>
		)
	}
}