import React from 'react';
import './NavigationBarStyles.css';
import { NavLink } from 'react-router-dom';

export const NavigationBar = () => (
<nav className="navbar">
	<div className="container">
		<div className="row justify-content-center">
			<div className="col-lg-9 col-md-12">
				<div className="row">
					<div className="col-12 col-md-4">
						<NavLink exact className="navbar-title" to="/">anoopsethu.</NavLink>
					</div>
					<div className="col-12 col-md-8">
						<div className="navbar-link-container">
							<NavLink exact className="navbar-link" activeClassName="active" to="/">home</NavLink>
							<NavLink exact className="navbar-link" activeClassName="active" to="/work">work</NavLink>
							<NavLink exact className="navbar-link" activeClassName="active" to="/about">about</NavLink>
							<NavLink exact className="navbar-link web-only" activeClassName="active" to="/words">words</NavLink>
							<NavLink exact className="navbar-link" activeClassName="active" to="/contact">contact</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</nav>
)