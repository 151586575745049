import React, {Component} from 'react';
import  './EmojiStyles.css';

export default class Emoji extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		var styleObject = {
			'height': this.props.size + 'px',
			'width': this.props.size + 'px',
			'backgroundSize': 'auto ' + this.props.size + 'px',
			'backgroundPositionX': + this.props.position + 'px',
		}
		return (
			<span style={styleObject} className={`emoji-container ${this.props.className}`}></span>	
		)
	}
}