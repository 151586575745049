import React, { Component }  from 'react';
import '../../css/article.css';
import SectionHeader from '../SectionHeader/SectionHeader';
import UserStory from '../UserStory/UserStory';
import CardOutline from '../CardOutline/CardOutline';
import ImageCaption from '../ImageCaption/ImageCaption';
import Quote from '../Quote/Quote';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import { work_items_featured } from '../../constants/work-items';
import MoreWork from '../MoreWork/MoreWork';
import {Helmet} from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class ThirtyDays extends Component {

	constructor(){
		super();
		this.state = {
		}
	}

	componentDidMount() {

    }

    componentWillUnmount() {

    }

	render() {
		return (
			<article className="mb-5">
				<Helmet>
					<title>30 Days of Malayalam Letters by Anoop Sethumadhavan - Full Stack Designer</title>
					<meta name="description" content="All about how I got fascinated by the world of Type and participated in 30 Days of Malayalam Letters Challenge, crafting my thoughts in HTML/CSS."
				    />
				</Helmet>
				<h1 className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					30 Day of Malayalam Letters
				</h1>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					All about how I got fascinated by the world of Type and participated in 30 Days of Malayalam Letters Challenge, crafting my thoughts in HTML/CSS.
				</p>
				<ProjectDetails timeline="Aug - Sep 2017" role="Lettring, Typography, Code" />
				<div className="bleeding-content mt-4">
					<LazyLoadImage wrapperClassName="cover-bg"
 					effect='blur'
					src='/img/30doml/cover.png' />
				</div>

				<SectionHeader number="01" title="How it all started"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					In 1400’s, Gutenberg invented movable typefaces, giving the world a cheaper way to obtain the written word. And that caused a revolution — typography became more than just a decorative element and evolved into not just an important form of art, but also a language itself in our contemporary society.
				</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Fast forward to 2018. 1st August, 2018 was when <a href="https://kdco.info/" target="_blank" className="normal-link">Kerala Designers Collaborative (KDCo)</a> launched the second edition of the 30 Days of Malayalam Letters campaign — the day when almost all social media sites got flooded with Malayalam letters being designed and interpreted by artists and designers in many ways, celebrating creative possibilities of type.
				</p>
				<ImageCaption fullWidth="false" image="/img/30doml/image-1.png" caption="Masthead for #30DOML, designed by Orion Champadiyil" />
				<p className="mt-0 mb-4">
					Modelled after the <a href="http://www.36daysoftype.com/" target="_blank" className="normal-link">36 Days of Type project</a>, the campaign was launched as an initiative aimed at celebrating the Malayalam language as well as the creativity of the artists and designers of the state.</p>
				<p className="mt-0 mb-4">
					For me personally, this was quite an exciting opportunity to explore the creative boundaries of type. I took the plunge and jumped right in. Although one thing kept me thinking - how do I make the challenge more fun and leverage the opportunity to grow my skills on parallel domains as well?</p>


				<SectionHeader number="02" title="Setting up a process and style"></SectionHeader>
				<p className="mt-0 mb-4">That was when I came across Lynn Fisher’s <a href="https://a.singlediv.com/" target="_blank" className="normal-link">Single Div project</a>. Needless to say, I was fascinated by the way she used CSS as an artistic medium to create amazing artworks, with just HTML and CSS! (For the uninitiated, HTML is the markup language of the web and CSS is used to style it).</p>
				<ImageCaption fullWidth="false" image="/img/30doml/image-2.png" caption="A few amazing artworks from Lynn's Single Div project" />
				<p className="mt-0 mb-4">Lynn’s process made me realise that CSS image crafting lies in an interesting intersection between vector illustration and front-end web development.</p>
				<Quote text="Creating a CSS image is essentially designing a vector graphic but instead of using vector illustration software (i.e Illustrator, Affinity Designer, Sketch) you are using CSS code in place of your toolbar."></Quote>
				<p className="mt-0 mb-4">Though not advised for a production workflow, I knew this could help me refine my web development skills while probing creative limits of type as well. So I was all buckled up for the ride, ready to explore one alphabet each day, crafting them in pure HTML/CSS.</p>



				<SectionHeader number="03" title="An exciting start"></SectionHeader>
				<p className="mt-0 mb-4">The initial few days were all about setting up the tone, mood and mental models right and exploring how to interpret complicated letterforms with code. It was indeed fun working on a rough pencil sketch marking a skeleton of the letterform, and then using <a href="https://codepen.io/" target="_blank" className="normal-link">Codepen</a> to code it up.</p>
				<div className="bleeding-content">
					<img src='/img/30doml/image-3.png' />
				</div>

				<SectionHeader number="04" title="There’s always more to explore"></SectionHeader>
				<p className="mt-0 mb-4">Plainly coding up characters seemed like a mundane job to me after the first couple of days. That was when I started exploring the creative side of the letterforms, translating them to the visual language. For most of the letters, this relation would be marked by some of the symbols, objects or references, predominantly used in the language while for some others, it would be the peculiar structure of the letterform itself. Brainstorming and discovering this relation turned out to be the most interesting part of the whole process.</p>
				<div className="bleeding-content">
					<img src='/img/30doml/image-4.png' />
				</div>
				<p className="mt-0 mb-4">Special Days and International observances were my constant inspiration. While most of the letterform representations came naturally (Ya [യ] — Yakshi [യക്ഷി], La [ല] — Lahari (ലഹരി)) certain other ones were very much linked to specific contexts (Tha [ത] — Thozhan [തോഴൻ] — World Friendship Day, Pa [പ] — Paattu [പാട്ട്] — World Music Day). The best part? Marking that connection between the observance and the letterform of the day.</p>
				<Quote text="During the days I didn’t feel like I was in the zone, exploring new things was my charger for inspiration. Dribbble and Behance, you be the best!"></Quote>

				<div className="bleeding-content">
					<img src='/img/30doml/image-5.png' />
				</div>
				<p className="mt-0 mb-4">On the 15th of August due to the <a href="https://en.wikipedia.org/wiki/2018_Kerala_floods" target="_blank" className="normal-link">Kerala Floods</a>, we discontinued the campaign as we realised it was not the right time for creative indulgence. Although just for 15 days, the campaign definitely helped me explore newer dimensions of web programming & scripting, while instilling a deeper appreciation for typography and lettering.</p>


				<SectionHeader number="05" title="What did I learn?"></SectionHeader>
				<p className="mt-0 mb-4">The sense of achievement you feel after visualising and implementing each letterform is so beyond words, and every single appreciation and recognition of your work boosts your confidence multifold. When you feel accepted and appreciated in the community, you get more power to keep going and do better.</p>
				<Quote text="Being engaged with the community is the best thing to learn and grow yourself both professionally & personally."></Quote>
				<p className="mt-0 mb-4">All thanks to Kerala Designers Collaborative, you guys rock!</p>
				<p className="mt-0 mb-4">Also, did I ever think of skipping a day in between? Well, to be honest, yes. But believe me - making up for it tomorrow is much harder than creating a small one today. Just put your inner critic to sleep. And if you are not happy with today’s work, don’t just quit - because you always have a tomorrow.</p>
				
				<hr/>
				<h3 className="mt-5 mb-3">Resources</h3>
				<ol>
					<li>HTML code for all the artworks mentioned above could be found on the Codepen collection <a className="normal-link" href="https://codepen.io/collection/DmQqqZ" target="_blank">here</a></li>
					<li>Have briefly explained the story behind every artwork on my <a className="normal-link" href="https://www.instagram.com/anoopsethu/" target="_blank">Instagram profile</a></li>
				</ol>


				<hr/>
				<MoreWork item1={work_items_featured[4]} item2={work_items_featured[0]} />

			</article>
		);
	}
}

