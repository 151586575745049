import React, { Component }  from 'react';
import './ContactStyles.css';

export default class Contact extends Component {

	constructor(){
		super();
		this.state = {
			drinksTooltip: false,
			drink: 'coffee'
		}
	    this.changeDrink = this.changeDrink.bind(this);
	}

	componentDidMount() {

    }

    componentWillUnmount() {

    }


	render() {
		return (
			<div className="mb-5">
				<div className="row">
					<div className="col-md-10">
						<h1 className="m-0 d-md-none animate__animated animate__fadeInUp animate__fast">
							Always down for a coffee, feel free to get in touch!
						</h1>
						<h1 className="d-none d-md-block noselect m-0 animate__animated animate__fadeInUp animate__fast">
							Always down for a 
							<span className="inline-block position-relative">
								<span onClick={this.changeDrink} id="interactive-link" className="interactive-link ml-2">
									{this.state.drink}
									
								</span>
							</span>
							, 
							feel free to get in touch!
						</h1>
					</div>
				</div>

				<div className="mt-4 animate__animated animate__fadeIn">
					{this.state.drink==='coffee'
						?
						<p className="intro-text mt-0 mb-4">
							If you want to chat about design, learn more about my past work, or just want to grab some coffee, I'd love to chat.
						</p>
						:
						<p className="intro-text mt-0 mb-4">
							Coffee, you're on the bench. Beer, suit up!
							<br/>*a few beers later* Umm, what were we talking about again?
						</p>
					}
					<a className="btn btn-primary" href="mailto:hello@anoopsethu.in?subject=Hello%20Anoop!">
						Get in touch
					</a>
				</div>
			</div>
		);
	}

	toggleTooltip() {
		this.setState({
			drinksTooltip: !this.state.drinksTooltip
		})
	}

	changeDrink(){
		if(this.state.drink === 'coffee'){
			this.setState({
				drink: 'beer',
			})
		} else {
			this.setState({
				drink: 'coffee',
			})
		}
		// console.log(this.state.drink);
	}
}

