import React, { Component }  from 'react';
import '../../css/article.css';
import SectionHeader from '../SectionHeader/SectionHeader';
import UserStory from '../UserStory/UserStory';
import CardOutline from '../CardOutline/CardOutline';
import ImageCaption from '../ImageCaption/ImageCaption';
import ProjectDetails from '../ProjectDetails/ProjectDetails';
import { work_items_featured } from '../../constants/work-items';
import MoreWork from '../MoreWork/MoreWork';
import {Helmet} from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class Webinars extends Component {

	constructor(){
		super();
		this.state = {
		}
	}

	componentDidMount() {

    }

    componentWillUnmount() {

    }

	render() {
		return (
			<article className="mb-5">
				<Helmet>
					<title>TapChief Webinars by Anoop Sethumadhavan - Full Stack Designer</title>
					<meta name="description" content="All about how we built a platform with no-code tools to host, organise and promote free online webinars from around the world."
				    />
				</Helmet>
				<h1 className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					TapChief Webinars
				</h1>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					TapChief Webinars started as an interesting hobby project at our HQ and evolved to platform to host, organize & promote online webinars from around the world.
				</p>
				<ProjectDetails timeline="Apr 2020" platform="Desktop, mobile web" role="Research, IA, UI, UX, Webflow" />

				<div className="bleeding-content mt-4">
					<LazyLoadImage wrapperClassName="cover-bg"
 					effect='blur'
					src='/img/webinars/cover.png' />
				</div>

				<SectionHeader number="01" title="How it all started"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					A few weeks into the COVID19 lockdown, we found the sudden surge in online events impossible to keep up with. There were so many insightful sessions happening every day, but no way to track what’s happening and where. </p>
				<ImageCaption fullWidth="false" image="/img/webinars/image-1.png" caption="More options, more confusion — the paradox of choice" />
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					That’s when we decided to build a one-stop repository for webinars, podcasts, live-sessions, and other online events happening all over the world across categories.</p>
				
				<SectionHeader number="02" title="Setting up the scene"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Alongside the preliminary research and competitor analysis, we also narrowed down on the TapChief colour palette to focus on context specific colours and explore more type options.</p>
				<ImageCaption fullWidth="false" image="/img/webinars/image-2.png" caption="The TapChief brand palette and type" />

				<SectionHeader number="03" title="Research"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">
					Our preliminary research on web-based tools and platforms in the domain made us realise that we were solving a relatively newer problem. Many of the existing solutions were primarily built for handling physical or offline meetup sessions and were not optimised for online or virtual events.</p>
				<div className="mt-4 mb-4">
					<div className="row">
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/thinking-face_1f914.png" background_color="#FFF1E4" description="Many of the existing solutions were primarily built for handling physical or offline meetup sessions"></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/globe-with-meridians_1f310.png" background_color="#E4FAFF" description="Not a lot of existing solutions are optimised for online or virtual events"></UserStory>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/left-pointing-magnifying-glass_1f50d.png" background_color="#EAE4FF" description="Drilled-down discovery of online events based on categories, figuring out speaker/organiser details was not possible"></UserStory>
						</div>
						<div className="col-12 col-lg-6">
							<UserStory image="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/bell_1f514.png" background_color="#FFE4E4" description="The experience of registering for an event & subscribing to notifications was too cumbersome"></UserStory>
						</div>
					</div>
				</div>

				<SectionHeader number="04" title="Next, make it pretty"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">We were quite clear on what the basic user flow should be. Also, we had made it a point to make sure that our users could find everything they need with very minimal effort. After figuring out the information architecture and a few low-fid sketches later, we were on Figma, crafting high-fid mockups for the user-flows.</p>
				<ImageCaption fullWidth="false" image="/img/webinars/image-3.png" caption="East or west, Figma is the best! 😍" />
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">Twelve hours and a dozen screens, countless iterations and a Marvel Prototype later, we finalised the UI — all in a single day (perks of working remotely).</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/webinars/screen-1.png" caption="TapChief Webinars - Home Page" />
				</div>

				<SectionHeader number="05" title="Time to build the app"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">We at TapChief have been part of the no-code ecosystem for a while. Since then, we’ve made Webflow an integral part of our design workflow, using it to build most of our statically hosted pages.</p>
				<ImageCaption fullWidth="false" image="/img/webinars/image-4.png" caption="The No-Code revolution is here! 🔥" />
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">Moreover, the solution we were trying to build had a basic listing structure, and we knew Webflow CMS would be helpful here in reducing the design-content gap. So we used it to set up a basic database model linking all data-attributes and then invited our marketing team to collaborate on this.</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">The best part? Our marketing team could manage the content on their own, without any dependencies from the design function.</p>

				<SectionHeader number="06" title="Curating the Webinars"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">From the get-go, we wanted to make sure our listings were accurate, well maintained, and periodically updated. We scanned online communities, (namely in domains like technology lifestyle, finance, startup) and selected the best of the lot to be showcased on the site.</p>
				<ImageCaption fullWidth="false" image="/img/webinars/image-5.png" caption="The web CMS for designers — Webflow’s CMS editor interface" />
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">Automating regular Keyword and Hashtag search on social media (Facebook, LinkedIn, Instagram, Twitter) and closely following industry stalwarts like Trello, Atlassian, GitHub, Todoist made sure we had enough legit listings in place. We had around 200 webinar details across diverse domains uploaded on the platform before the launch. In addition to these, we made sure it is easy enough for visitors to suggest new webinars to be added to the list.</p>
				<SectionHeader number="07" title="Keep calm, it’s Launch Day!"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">After three days of conceptualisation, ideation and execution, we launched the platform on 16th April 2020. We were genuinely surprised by the overwhelming response from the community — low bounce rates and long session durations made us confident and gave us the energy to keep going and build better.</p>
				<div className="bleeding-content">
					<ImageCaption caption="Small wins! ❤️" fullWidth="true" image="/img/webinars/image-6.png" />
				</div>

				<SectionHeader number="08" title="What's next?"></SectionHeader>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">In addition to having partnerships with more organisations and facilitating better listings on the platform, we’re planning to collaborate with influencers to host live training and workshop sessions. Parallelly, we’re in constant touch with our users, evaluating their experience and gaining insights on how to make things better.</p>
				<p className="mt-0 mb-4 animate__animated animate__fadeInUp animate__fast">Immensely proud of what we’ve built — something that started as a small side project idea is now helping thousands to learn from experts, stay up-to-date, and keep their mind off the pandemic.</p>
				<div className="bleeding-content">
					<ImageCaption fullWidth="true" image="/img/webinars/screen-3.png" />
				</div>

				<hr/>
				<MoreWork item1={work_items_featured[0]} item2={work_items_featured[3]} />

			</article>
		);
	}
}

