import React, {Component} from 'react';
import './WorkCardStyles.css';
import { NavLink } from 'react-router-dom';

export default class WorkCard extends Component {
	constructor(){
		super();
		this.state = {
		}
	}
	render() {
		return (
		this.props.data['internal_link']?
		<NavLink exact to={this.props.data['internal_link']} style={{backgroundColor: this.props.data['background_color']}} className={`d-block work-card-container animate__animated animate__fadeInUp animate__fast ${this.props.class}`}>
			
			<div className="row">
			{this.props.type!='small'?
				<div className="col-md-6">
					<h2 className="header">
						{this.props.data['title']} 
					</h2>
					<p className="description">{this.props.data['description']}</p>
				</div>
			:
				<div className="col-md-7">
					<h2 className="header">
						{this.props.data['title']} 
					</h2>
					<p className="description">{this.props.data['description']}</p>
				</div>
			}
			</div>

			{this.props.type!='small' && (
				<h6 className="role m-0 d-flex align-items-center">
					<span>Read the story</span>
					<img src="/img/arrow-right.svg" />
				</h6>
			)}

			<img src={this.props.data['image']} className="preview-image" />
		</NavLink>
		:
		<a href={this.props.data['external_link']} target="_blank" style={{backgroundColor: this.props.data['background_color']}} className={`d-block work-card-container animate__animated animate__fadeInUp animate__fast ${this.props.class}`}>
			
			<div className="row">
				<div className="col-md-6">
					<h2 className="header">
						{this.props.data['title']}
					</h2>
					<p className="description">{this.props.data['description']}</p>
				</div>
			</div>
			<h6 className="role m-0 d-flex align-items-center">
				<span>Learn more</span>
				<img src="/img/arrow-right.svg" />
			</h6>
			<img src={this.props.data['image']} className="preview-image" />
		</a>
		)
	}
}